import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import ColumnH1 from '../atoms/columnH1'
import Introduction004 from './Introduction004'
import Introduction005 from './Introduction005'
import Introduction006 from './Introduction006'
import Introduction007 from './Introduction007'
import Introduction008 from './Introduction008'
import Introduction009 from './Introduction009'
import Introduction010 from './Introduction010'
import Introduction011 from './Introduction011'
import Introduction012 from './Introduction012'

const Wrapper = styled.article`
  position: relative;
  margin-bottom: 30px;
  .p-txt-date {
    margin-top: -10px;
    color: #ababab;
  }
  section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 16px;
    color: #505050;
    line-height: 1.6em;
    h1 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #f5f5f5;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-bottom: 30px;
      margin-top: 10px;
      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #77d7c9;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }
    h2 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #ffffff;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-top: 10px;
      margin-bottom: 30px;
      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #d8d8d8;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }
    h3 {
      color: #414141;
      font-size: 16px;
      line-height: 1.4em;
      background: #ffffff;
      width: auto;
      padding: 10px 0 8px;
      margin-top: -10px;
      margin-bottom: 20px;
      border-top: solid 2px #e5e5e5;
      border-bottom: solid 2px #e5e5e5;
    }
    p {
      padding-bottom: 30px;
      line-height: 2;
      position: relative;
      span {
        background: linear-gradient(transparent 60%, #ff6 60%);
        font-weight: 600;
      }
      .p-list-link {
        position: absolute;
        top: 6px;
        right: 2px;
        &:before {
          display: block;
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          top: 7px;
          left: -14px;
          border-left: 8px solid #3ec7b3;
          border-top: 4px solid transparent;
          border-bottom: 4px solid transparent;
        }
      }
      &.txt-color-red {
        color: #ff6969;
      }
    }
    .link-div {
      width: 100%;
      display: flex;
      justify-content: left;
      padding-bottom: 30px;
      > div {
        width: 50px;
      }
      .link-p {
        width: calc(100% - 50px);
        .p-list-link {
          position: absolute;
          top: -3px;
          left: 30px;
          &:before {
            display: block;
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            top: 11px;
            left: -14px;
            border-left: 8px solid #3ec7b3;
            border-top: 4px solid transparent;
            border-bottom: 4px solid transparent;
          }
        }
      }
    }
    img {
      width: 100%;
      height: auto;
      padding-bottom: 30px;
    }
  }
  ${BreakPoints.large} {
  }
`
type Props = {
  /** 広告の番号 */
  no: number
}

/**
 * コラム記事詳細：記事本体
 * @constructor
 */
const ColumnDetailInner0041 = (props: Props): JSX.Element => (
  <Wrapper>
    <ColumnH1 label="介護休暇とは？介護休業との違いや基本的な内容を解説" />
    {/** TODO 川上申し送り事項：▼投稿日をここに表示 */}
    <p className="p-txt-date">2023.01.13</p>
    <section>
      {/** TODO 川上申し送り事項：▼記事投稿（？）で作成された内容がここに入る予定 */}
      <p>
        介護休暇とは、家族を介護するために取得する休暇として、ワーク・ライフ・バランスや働き方改革が推奨される中、効果的な制度として打ち出されています。しかし、介護休業と類似した名称ということもあり、混同されるケースもしばしばみられます。
        <br />
        そこで今回は、介護休暇とはどのような制度なのか、取得対象者や対象となる家族の詳細、取得単位や気になる介護休業との違いについてわかりやすく解説していきましょう。
      </p>
      <img src="/images/column/details/c0041.jpg" alt="キンクラコラム画像41" />
      <h1>「介護休暇」とは</h1>
      <p>
        介護休暇とは、要介護状態である対象家族の介護をするための時間として、
        <span>労働者が取得することができる休暇</span>をいいます。
        <br />
        この「介護」には、文字通り介護を行う時間に加え、病院や施設への付き添い時間や、介護サービスを利用する際の手続き代行のための時間も含まれます。また、ケアマネージャーとの打ち合わせなどに充てることも可能です。
        <br />
        なお、要介護状態とは、育児・介護休業法という法律によれば、負傷・疾病、身体や精神上の障害をもち、
        <span>2週間以上にわたって常に介護を必要とする状態</span>
        のことです。これは、介護保険サービスを利用する際に受ける「要介護認定」とは異なる点に注意が必要です。
        <br />
        要介護状態であることを証明する書類には、医師の診断書のみならず、介護状態の事実を証明することができる書類であれば認められます。
        <br />
        ただし、会社側は介護休暇を取得する労働者からの証明書類提出を義務付けることはできず、また、証明書類を提出しない場合に休暇を取得させないことも認められていません。
      </p>

      <h1>介護休業との違いは</h1>
      <p>
        介護休業と介護休暇は名称も似ており、要介護状態の対象家族を介護するための休みであることも同じですが、取得日数等大きく異なる点があります。
        <br />
        介護休業は、
        <span>
          対象家族1人あたり通算93日まで取得することができるまとまった休業
        </span>
        です。<span>3回までを上限</span>
        として、分割して取得することも可能のため、長期にわたる家族の介護が必要とされる労働者が取得をするものです。
        <span>介護休業は、介護休業給付金制度</span>
        という国による給付金制度を利用することができるため、休業期間中は労働者の生活保障のため一定金額を受け取る事が可能です。
        <br />
        <br />
        一方、介護休暇は、日々の介護生活の中で必要となる
        <span>短時間の用事を済ませるために活用する制度</span>
        であるといえるでしょう。ただし、介護休暇中の賃金支払いについては法律による決まりがないため、
        <span>会社によって賃金支払の有無が異なる</span>点に注意が必要です。
        <br />
        また、介護休業を取得する場合は、正式な開始日、終了日を労使間で決定した上で、会社側からの行政への手続きが必要となりますが、介護休暇を取得する場合は、あらかじめ会社側が就業規則により対象労働者や取得日数、取得単位などについて定めておき、その定めに応じた形で手続きを進める必要があります。
      </p>

      {/** ここに9パターンの広告設定する */}
      {props.no === 0 && <Introduction004 />}
      {props.no === 1 && <Introduction005 />}
      {props.no === 2 && <Introduction006 />}
      {props.no === 3 && <Introduction007 />}
      {props.no === 4 && <Introduction008 />}
      {props.no === 5 && <Introduction009 />}
      {props.no === 6 && <Introduction010 />}
      {props.no === 7 && <Introduction011 />}
      {props.no === 8 && <Introduction012 />}

      <h1>介護休暇の取得対象者</h1>
      <p>
        介護休暇の取得をすることができる対象は、前述の
        <span>「要介護状態である対象家族」</span>
        を介護する労働者で、男女の性別は問いません。ただし、日雇い労働者は除かれる点に注意しましょう。
        <br />
        さらに、入社して半年に満たない労働者や、1週間あたりの所定労働日数が2日以下の労働者については、労使協定を締結することで介護休暇取得の対象外とすることができます。なお、労使協定とは、労働者の代表者や労働組合と事業主が書面で交わす協定のことです。
      </p>
      <h1>介護休暇の対象家族</h1>
      <p>
        介護休暇の対象家族とは、労働者からみて
        <span>
          配偶者、父母、子供、義父母（配偶者の両親）、祖父母、兄弟姉妹、孫
        </span>
        にあたる者が含まれます。
        <br />
        配偶者については、事実婚である者も含まれます。また、子については、法律で親子関係と認められる者のみが対象であり、養子も含まれる点に注意が必要です。
      </p>
      <h1>介護休暇の取得日数</h1>
      <p>
        介護休暇として取得できる日数は、<span>1年度あたりでカウント</span>
        されます。この年度は、決算年度によって設定することもできますが、特段の定めがない場合は毎年4月1日から翌年の3月31日までとなります。
        <br />
        具体的な日数は、対象家族1人につき5日で、対象家族が2人以上の場合は10日となります。この「2人以上」というのがポイントで、
        <span>
          対象家族がたとえば3人以上の場合でも、1年度あたりで認められる介護休暇日数は10日
        </span>
        です。
        <br />
        なお、対象家族が2人以上いる場合に付与される10日は、対象家族で均等に按分する必要はなく、1人の対象家族に集中して10日分を利用することも認められています。
        <br />
        <br />
        介護休暇の取得単位は、1日単位や半日単位という区分に加えて、令和3年以降は法改正により
        <span>時間単位での取得</span>
        も認められています。より細かい刻みで取得することを認めることで、介護と仕事を両立する社員が柔軟にスケジュールを組むことができる環境づくりが求められています。
      </p>
      <h1>まとめ</h1>
      <p>
        介護休暇は、介護休業に比べ新しく創設された制度となるため、企業・労働者ともに認知度がそこまで高くないという現状もあります。
        <br />
        今後、超高齢化社会下で介護が必要となるケースが増加することが予想される中、介護を理由に退職する社員が増加することは優秀な人材の流出という危機につながります。企業側には、介護休暇への理解を深め、制度を整えることが早急に求められています。
      </p>
    </section>
  </Wrapper>
)

export default ColumnDetailInner0041
